import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { Canvas, useFrame, useLoader } from 'react-three-fiber';
import { Box, Torus, Sphere, OrbitControls } from '@react-three/drei';

import { FontLoader } from './three/FontLoader.js';

import { extend } from 'react-three-fiber';
import { TextGeometry } from './three/TextGeometry.js';

extend({ TextGeometry });


function WireframeText() {
  const [font, setFont] = useState(null);

  useEffect(() => {
    new FontLoader().load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', loadedFont => {
      setFont(loadedFont);
    });
  }, []);

  const textConfig = useMemo(() => ({
    font: font,
    size: 1,
    height: 0.1,
    curveSegments: 32,
    bevelEnabled: false
  }), [font]);

  if (!font) return null;

  return (
    <group position={[0, 2.5, 0]}>
      <mesh>
        <primitive object={new TextGeometry('Anima', textConfig)} />
        <meshBasicMaterial attach="material" wireframe={true} color="white" />
      </mesh>
    </group>
  );
}

function WireframeTextLong() {
  const [font, setFont] = useState(null);

  useEffect(() => {
    new FontLoader().load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', loadedFont => {
      setFont(loadedFont);
    });
  }, []);

  const textConfig = useMemo(() => ({
    font: font,
    size: 1,
    height: 0.1,
    curveSegments: 32,
    bevelEnabled: false
  }), [font]);

  if (!font) return null;

  return (
    <group position={[-10, -10, -10]}>
      <mesh>
        <primitive object={new TextGeometry('Capsule, Bloq, \nDollar Store Kids, Ralley, \nDentsu, Ordinary Oranges, \nMcCann NY, Orollo, \nLandia, ...', textConfig)} />
        <meshBasicMaterial attach="material" wireframe={true} color="white" />
      </mesh>
    </group>
  );
}

function randomDirection() {
  return Math.random() > 0.5 ? 0.01 : -0.01;
}

function MovingShapes() {
  const meshRef = useRef();
  const torusRef = useRef();
  const sphereRef = useRef();

  // Set initial velocities with random directions
  const [boxVelocity, setBoxVelocity] = useState({
    x: randomDirection(),
    y: randomDirection(),
    z: randomDirection()
  });
  const [torusVelocity, setTorusVelocity] = useState({
    x: randomDirection(),
    y: randomDirection(),
    z: randomDirection()
  });
  const [sphereVelocity, setSphereVelocity] = useState({
    x: randomDirection(),
    y: randomDirection(),
    z: randomDirection()
  });

  useFrame(() => {
    if (meshRef.current && torusRef.current && sphereRef.current) {
      // Move the objects around
      ['x', 'y', 'z'].forEach(dir => {
        meshRef.current.position[dir] += boxVelocity[dir];
        torusRef.current.position[dir] += torusVelocity[dir];
        sphereRef.current.position[dir] += sphereVelocity[dir];
      });

      // Handle box boundaries and bounce
      ['x', 'y', 'z'].forEach(dir => {
        if (Math.abs(meshRef.current.position[dir]) > 2) {
          setBoxVelocity(v => ({ ...v, [dir]: -v[dir] }));
        }
        if (Math.abs(torusRef.current.position[dir]) > 2) {
          setTorusVelocity(v => ({ ...v, [dir]: -v[dir] }));
        }
        if (Math.abs(sphereRef.current.position[dir]) > 2) {
          setSphereVelocity(v => ({ ...v, [dir]: -v[dir] }));
        }
      });
    }
  });

  return (
    <>
      <Box ref={meshRef} args={[1, 1, 1]} position={[0, 0, 0]}>
        <meshBasicMaterial wireframe={true} color="hotpink" />
      </Box>
      <Torus ref={torusRef} args={[0.5, 0.25, 16, 100]} position={[0, 0, 0]}>
        <meshBasicMaterial wireframe={true} color="lightblue" />
      </Torus>
      <Sphere ref={sphereRef} args={[0.5, 32, 32]} position={[0, 0, 0]}>
        <meshBasicMaterial wireframe={true} color="gold" />
      </Sphere>
    </>
  );
}

function App() {
  return (
    <>
      <p style={{
          color: "white",
          position: "absolute",
          top: "10px",
          left: "10px",
          margin: 0,
          zIndex: 2
      }}>
        Web2.5 Consultancy for Brands
      </p>
      <Canvas
        camera={{ position: [5, 5, 10], fov: 40 }}
        style={{ background: 'black', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Suspense fallback={null}>
          <WireframeText />
          <WireframeTextLong />
          <MovingShapes />
        </Suspense>
        <OrbitControls />
      </Canvas>
      <p style={{
          color: "white",
          position: "absolute",
          bottom: "10px",
          left: "10px",
          margin: 0,
          zIndex: 2
      }}>
        business@anima.xyz
      </p>
    </>
  )
}

export default App;
